*:focus-visible {
  outline: 2px solid var(--chakra-colors-cyan-400);
  outline-offset: 2px;
  outline-color: var(--chakra-colors-cyan-400) !important;
}

/* Offset for :target */
:target {
  padding-top: 100px;
  margin-top: -100px;
}

.truncate {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.chakra-heading > article > p:first-of-type {
  margin-top: 0;
}
